import { useLayoutEffect } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Progress = ({ html }) => {
  let links = []
  const LinkRegExp = /<a href="(.*)">(.*)<\/a>/gm
  let match
  while ((match = LinkRegExp.exec(html))) {
    let [, href, name] = match
    links.push([href, name])
  }

  useLayoutEffect(() => {
    let cleanups = []
    let observer = new IntersectionObserver(
      function(entries) {
        // filter through all entries to only use the one that are intersecting
        //
        let intersecting = entries.filter(
          ({ isIntersecting }) => isIntersecting
        )

        // bail out if there are none
        //
        if (intersecting.length === 0) return

        let current = intersecting[0]
        let href = current.target.id

        trackCustomEvent({
          // string - required - The object that was interacted with (e.g.video)
          category: window.location.pathname,
          // string - required - Type of interaction (e.g. 'play')
          action: href,
          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
          // label: href,
          // number - optional - Numeric value associated with the event. (e.g. A product ID)
          // value: current.intersectionRatio,
        })
      },
      { rootMargin: "-30% 0% -60% 0%" }
    )

    for (let [href] of links) {
      let node = document.querySelector(href)
      observer.observe(node)
      cleanups.push(() => observer.unobserve(node))
    }

    return () =>
      cleanups.forEach(func => {
        func.call()
      })
  })

  return null
}

export default Progress
