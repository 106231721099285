import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Progress from "../components/progress"
import Markdown from "../components/markdown"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark
  let classNames = "max-w-xl markdown blockquoteAsInfoBox"
  if (post.parent.base === "Curriculum Vitae.md") {
    classNames += " markdown-cv "
  }
  return (
    <Layout>
      <SEO title={post.parent.name} />
      <Progress html={post.tableOfContents} />

      <Markdown className={classNames} html={post.html} />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents(absolute: false)
      frontmatter {
        title
      }
      parent {
        ... on File {
          id
          name
          base
        }
      }
    }
  }
`
